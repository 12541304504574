import React from 'react';
import { File } from '@model/generated/index';
import { Layout } from '@components/global/Layout';
import { graphql, PageProps } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { navigationItemsProjects } from '@model/projekte/navigationItemsProjects';
import { Meta } from '@components/global/Meta';

type DataProps = {
  image1: File;
  image2: File;
  image3: File;
  image4: File;
  image5: File;
  image6: File;
};

type Props = PageProps<DataProps>;

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "projekte/wortfindungsamt/image1.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
    image2: file(relativePath: { eq: "projekte/wortfindungsamt/image2.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
    image3: file(relativePath: { eq: "projekte/wortfindungsamt/image3.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
    image4: file(relativePath: { eq: "projekte/wortfindungsamt/image4.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
    image5: file(relativePath: { eq: "projekte/wortfindungsamt/image5.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
    image6: file(relativePath: { eq: "projekte/wortfindungsamt/image6.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
  }
`;

const Page: React.FC<Props> = (props) => {
  const { data } = props;
  return (
    <Layout title="Projekte" navigationItems={navigationItemsProjects}>
      <div className="flex">
        <div className="flex-auto p-32">
          <iframe
            title="1 Minute audiovisuelle Freude"
            className="h-256 lg:h-512"
            frameBorder="0"
            allow="fullscreen; display-capture; autoplay"
            src="https://www.youtube.com/embed/6kB1i2ELAd0"
            width="100%"
          />
          <div className="flex-1 py-32 mb-16">
            <h3>Sigrid Sandmann</h3>
            <p>
              Die beteiligten Menschen vor Ort, deren in Sprache formulierte und
              in Worten bildhaft sichtbar gemachte Gedanken, sind meist die
              Hauptakteure der künstlerischen Konzepte von{' '}
              <b>Sigrid Sandmann</b>.
            </p>
            <div className="flex flex-col my-32">
              <GatsbyImage
                className="my-8 md:my-0 max-w-672 w-full"
                objectFit="contain"
                image={data.image2.childImageSharp.gatsbyImageData}
                alt="Projekt Wortfindungsamt"
              />
            </div>
            <p>
              Ihre Kunst ist besonders geprägt durch die gesellschaftlichen und
              technologischen Entwicklungen der letzten Jahrzehnte. Dennoch ist
              sie eine Künstlerin, die stets das gegenwärtige Geschehen in den
              Focus stellt. Ihr Werk ist nur denkbar mit dem ihr eigenen,
              kritisch respektvollen Rückblick auf die individuelle Geschichte
              der Personen, mit denen sie sich auseinandersetzt und die Historie
              der Orte, die sie erforscht. An jeder Stelle und zu jeder Zeit
              sich auch öffentlich äußern zu können, ist ein unschätzbares
              Privileg von Selbstbestimmung und Freiheit. Dieses Recht ist
              allerdings verbunden mit einer immensen Verantwortung für die
              Gemeinschaft. Der Erfolg der Künstlerin liegt deshalb nicht
              zuletzt in der hohen Sensibilität gegenüber den Menschen, denen
              sie offen und mit großer Wertschätzung bei ihrer Arbeit begegnet.
            </p>
            <div className="flex flex-col my-32">
              <GatsbyImage
                className="my-8 md:my-0 max-w-672 w-full"
                objectFit="contain"
                image={data.image1.childImageSharp.gatsbyImageData}
                alt="Projekt Wortfindungsamt"
              />
              <GatsbyImage
                className="my-8 md:my-0 md:-mt-40 lg:-mt-92 self-end w-full md:w-1/2"
                objectFit="cover"
                image={data.image3.childImageSharp.gatsbyImageData}
                alt="Projekt Wortfindungsamt"
              />
            </div>
            <p>
              Zumeist ist der öffentliche Raum das Forum, auf dem{' '}
              <b>Sigrid Sandmann</b> ihre künstlerischen Positionen im Kontext
              zu Mitwirkenden und Betrachtern auslotet und definiert.
              Interviews, Bildbefragungen und Ortserkundungen vernetzt sie mit
              Typographie, digitalen Medien und Licht zu ihrem ganz persönlichen
              und unverwechselbaren Handwerkszeug, das ihr ermöglicht, aktuelle
              Inhalte im wirklichen Sinn des Wortes zu beschreiben und zu
              beleuchten.
            </p>
            <div className="flex flex-col my-32">
              <GatsbyImage
                className="my-8 md:my-0 md:-mb-32 lg:-mb-68 self-end w-full md:w-1/2 z-10"
                objectFit="cover"
                image={data.image4.childImageSharp.gatsbyImageData}
                alt="Projekt Wortfindungsamt"
              />
              <GatsbyImage
                className="my-8 md:my-0 max-w-672 w-full"
                objectFit="contain"
                image={data.image5.childImageSharp.gatsbyImageData}
                alt="Projekt Wortfindungsamt"
              />
              <GatsbyImage
                className="my-8 md:my-0 md:-mt-80 lg:-mt-160 self-end w-full md:w-1/2"
                objectFit="cover"
                image={data.image6.childImageSharp.gatsbyImageData}
                alt="Projekt Wortfindungsamt"
              />
            </div>
            <div>
              <p>
                Zumeist ist der öffentliche Raum das Forum, auf dem{' '}
                <b>Sigrid Sandmann</b> ihre künstlerischen Positionen im Kontext
                zu Mitwirkenden und Betrachtern auslotet und definiert.
                Interviews, Bildbefragungen und Ortserkundungen vernetzt sie mit
                Typographie, digitalen Medien und Licht zu ihrem ganz
                persönlichen und unverwechselbaren Handwerkszeug, das ihr
                ermöglicht, aktuelle Inhalte im wirklichen Sinn des Wortes zu
                beschreiben und zu beleuchten. Kommunikation, sich äußern,
                Verständigung mit Wörtern in Schrift und Sprache erzeugen –
                Inhalte, Informationen verstehen und durch Texte weitergeben –
                Wissen vermitteln, Beteiligung, Unabhängigkeit und
                Gleichberechtigung initiieren – all diese Aspekte finden sich in
                unterschiedlicher Gewichtung in den Projekten von{' '}
                <b>Sigrid Sandmann</b> und ziehen sich wie eine imaginäre Linie
                durch viele ihrer Arbeiten im In- und Ausland.
              </p>
              <p className="pt-32">
                <b>Standort:</b>
                <br />
                Das Wortfindungsamt befindet sich in der Innenstadt von
                Lüdenscheid Rathausplatz - Vor der Hauptpost
              </p>
              <p className="pt-32">
                <b>Öffnungszeiten:</b>
                <br />
                17. bis 26. September 2021
                <br />
                Täglich 15:00 Uhr bis 18:00 Uhr
                <br />
                Außer Sa. und Mi. 10:00 Uhr bis 14:00 Uhr
              </p>
              <p className="pt-16">
                Am Sonntag, den 26. September 2021 ist nur noch Wortausgabe!
              </p>
              <p className="pt-16">
                Für Schulklassen und größere Gruppen bitte mit Voranmeldung:
                <br />
                <a href="mailto:wort@wortfindungsamt.de">
                  wort@wortfindungsamt.de
                </a>
              </p>
              <p className="pt-32">
                Zu den Webseiten der Künstlerin und des Projektes:
                <b className="flex flex-col">
                  <a
                    href="https://www.sigrid-sandmann.de/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Sigrid Sandmann
                  </a>
                  <a
                    href="https://www.wortfindungsamt.de/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Wortfindungsamt
                  </a>
                  <a
                    href="https://utopie-archiv.de/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Utopie Archiv
                  </a>
                </b>
              </p>
            </div>
          </div>
        </div>
        <h1 style={{ writingMode: 'vertical-rl' }}>Wortfindungsamt</h1>
      </div>
    </Layout>
  );
};

export const Head: React.FC<Props> = (props) => {
  return (
    <Meta
      description="Die beteiligten Menschen vor Ort, deren in Sprache formulierte und
      in Worten bildhaft sichtbar gemachte Gedanken, sind meist die
      Hauptakteure der künstlerischen Konzepte von Sigrid Sandmann"
      publicURL={props.data.image2.publicURL}
      title="Wortfindungsamt"
    />
  );
};

export default Page;
